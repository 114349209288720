if (!Object.assign) {
  Object.defineProperty(Object, 'assign', {
    enumerable: false,
    configurable: true,
    writable: true,
    value: function(target) {
      'use strict';
      if (target === undefined || target === null) {
        throw new TypeError('Cannot convert first argument to object');
      }

      var to = Object(target);
      for (var i = 1; i < arguments.length; i++) {
        var nextSource = arguments[i];
        if (nextSource === undefined || nextSource === null) {
          continue;
        }
        nextSource = Object(nextSource);

        var keysArray = Object.keys(Object(nextSource));
        for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
          var nextKey = keysArray[nextIndex];
          var desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
          if (desc !== undefined && desc.enumerable) {
            to[nextKey] = nextSource[nextKey];
          }
        }
      }
      return to;
    }
  });
}


var lang = $("html").attr("lang");
var cookiesLangs = {
  ca: {
    title: 'Accepta cookies',
    message: 'Utilitzem cookies pròpies i de tercers per millorar l’experiència d’usuari, analitzar el trànsit del lloc web i personalitzar el contingut. En fer clic a "Accepta les cookies", accepteu l’ús de les cookies descrites a la nostra <a href="cookies_link">Política de cookies</a>. També podeu configurar quines cookies voleu acceptar fent clic a “Configurar les cookies”.',
    link: '/ca/politica-de-cookies/',
    acceptBtnLabel: 'Accepta totes les cookies',
    acceptBtnLabel2: 'Accepta les cookies',
    advancedBtnLabel: 'Configurar les cookies',
    moreInfoLabel: 'Més informació',
    cookieTypesTitle: 'Selecciona les galetes que vols acceptar',
    fixedCookieTypeLabel: 'Necessàries',
    fixedCookieTypeDesc: 'Aquestes galetes són essencials perquè la web funcioni correctament.'
  },
  es: {
    title: 'Aceptar Cookies',
    message: 'Utilizamos cookies propias y de terceros para mejorar la experiencia de usuario, analizar el tráfico del sitio web y personalizar el contenido. Al hacer clic en "Aceptar las cookies", aceptas el uso de las cookies descritas en nuestra  <a href="cookies_link">Política de cookies</a>. También puedes configurar qué cookies deseas aceptar haciendo clic en "Configurar las cookies".',
    link: '/es/politica-de-cookies/',
    acceptBtnLabel: 'Aceptar todas las cookies',
    acceptBtnLabel2: 'Aceptar las cookies',
    advancedBtnLabel: 'Configurar las cookies',
    moreInfoLabel: 'Más información',
    cookieTypesTitle: 'Selecciona las cookies que quieres aceptar',
    fixedCookieTypeLabel: 'Necesarias',
    fixedCookieTypeDesc: 'Estas cookies són esenciales para que la web funcione correctamente.'
  },
  en: {
    title: 'Accept cookies',
    message: 'We use our own and third-party cookies to improve user experience, and analyze website traffic and personalize content. By clicking "Accept Cookies", you consent the use of the cookies described in our  <a href="cookies_link">Cookies Policy</a>. You can also decide which cookies you want to accept by clicking  "Configure cookies".',
    link: '/en/politica-de-cookies/',
    acceptBtnLabel: 'Accept all cookies',
    acceptBtnLabel2: 'Accept cookies',
    advancedBtnLabel: 'Configure cookies',
    moreInfoLabel: 'More information',
    cookieTypesTitle: 'Select the cookies you want to accept',
    fixedCookieTypeLabel: 'Required',
    fixedCookieTypeDesc: 'These cookies are essential for the website to function properly.'
  }
}
var cookiesLangsMarketing = {
  ca: {
    message: 'Utilitzem cookies pròpies i de tercers per millorar l’experiència d’usuari, analitzar el trànsit del lloc web i personalitzar el contingut i anuncis. En fer clic a "Accepta les cookies", accepteu l’ús de les cookies descrites a la nostra  <a href="cookies_link">Política de cookies</a>. També podeu configurar quines cookies voleu acceptar fent clic a “Configurar les cookies”.',
  },
  es: {
    message: 'Utilizamos cookies propias y de terceros para mejorar la experiencia de usuario, analizar el tráfico del sitio web y personalizar el contenido y anuncios. Al hacer clic en "Aceptar las cookies", aceptas el uso de las cookies descritas en nuestra  <a href="cookies_link">Política de cookies</a>. También puedes configurar qué cookies deseas aceptar haciendo clic en "Configurar las cookies".',
  },
  en: {
    message: 'We use our own and third-party cookies to improve user experience, and analyze website traffic and personalize content and ads. By clicking "Accept Cookies", you consent the use of the cookies described in our  <a href="cookies_link">Cookies Policy</a>. You can also decide which cookies you want to accept by clicking  "Configure cookies".',
  }
}
var cookiesTypes = {
  ca: {
    preferences: {
      type: 'De personalització',
      value: 'preferences',
      description: 'Es tracta de cookies relacionades amb les preferències del vostre lloc, per ex. recordant l\'idioma, nom d\'usuari, etc.'
    },
    analytics:{
      type: 'D\'anàlisi',
      value: 'analytics',
      description: 'Cookies relacionades amb visites al lloc, tipus de navegadors, etc.'
    },
    marketing: {
      type: 'De màrqueting',
      value: 'marketing',
      description: 'Cookies relacionades amb el màrqueting, p. ex. butlletins, xarxes socials, etc.'
    }
  },
  es: {
    preferences: {
      type: 'De personalización',
      value: 'preferences',
      description: 'Estas son cookies que están relacionadas con las preferencias de su sitio, por ej. recordando el idioma, nombre de usuario, etc.'
    },
    analytics: {
      type: 'De análisis',
      value: 'analytics',
      description: 'Cookies relacionadas con visitas al sitio, tipos de navegador, etc.'
    },
    marketing: {
      type: 'De marketing',
      value: 'marketing',
      description: 'Cookies relacionadas con el marketing, p. Ej. boletines, redes sociales, etc.'
    }
  },
  en: {
    preferences: {
      type: 'Personalization',
      value: 'preferences',
      description: 'These are cookies that are related to your site preferences, e.g. remembering language, username, etc.'
    },
    analytics: {
      type: 'Analysis',
      value: 'analytics',
      description: 'Cookies related to site visits, browser types, etc.'
    },
    marketing: {
      type: 'Marketing',
      value: 'marketing',
      description: 'Cookies related to marketing, e.g. newsletters, social media, etc.'
    }
  }
}

function cookiesActivate() {
  if ($.fn.ihavecookies.preference('preferences') === true) {
    var date = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    document.cookie = "lang="+lang+"; expires="+date.toGMTString()+"; path=/";
  }
  if ($.fn.ihavecookies.preference('analytics') === true && typeof cookiesAnalytics === "function") {
    cookiesAnalytics();
  }
  if ($.fn.ihavecookies.preference('marketing') === true && typeof cookiesMarketing === "function") {
    cookiesMarketing();
  }
}
var options = Object.assign(cookiesLangs[lang], {
  cookieTypes: [
    cookiesTypes[lang]['preferences']
  ],
  delay: 300,
  expires: 90,
  onAccept: function() {
    cookiesActivate();
  },
  uncheckBoxes: true,
});

if (typeof cookiesAnalytics === "function") {
  options.cookieTypes.push(cookiesTypes[lang]['analytics']);
}
if (typeof cookiesMarketing === "function") {
  options.cookieTypes.push(cookiesTypes[lang]['marketing']);
  options = Object.assign(options, cookiesLangsMarketing[lang]);
}
options.message = options.message.replace('cookies_link', options.link);
$(document).ready(function() {
    $('body').ihavecookies(options);
    cookiesActivate();
});
